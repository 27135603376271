import useProduct from '@/views/product/useProduct'
import ProductModal from '@/modal/productModal'
import {
  ref, reactive, watch, computed,
} from '@vue/composition-api'
import util from '@/global/util'
import store from '@/store'
import http from '@/global/http'
import constant from '@/global/const'

export default function useProductOfficeDetail() {
  const {
    actions,
    productMenuType,
    productMenuTypeVI,
    //
    fetchProduct,
    fetchSuggestedListCustomer,
    closeDealProduct,
    openDealProduct,
    refreshProduct,
  } = useProduct()
  const today = new Date()
  const closeDealSignAtMonth = ref(today.getMonth() + 1)
  const closeDealSignAtYear = ref(today.getFullYear())
  const closeDealExpireMonth = ref('')
  const closeDealExpireYear = ref('')
  const closeDealData = ref({
    isNotHaveInfoExpired: null,
    expireMonth: '',
    expireYear: '',
    expire_month: '',
  })
  const suggestedList = ref([])
  const fitFilter = reactive({
    is_not_exported: ref(''),
    user_id: ref(''),
  })
  const product = ProductModal()
  const tableColumnsSuggestedCustomer = [
    {
      label: 'Mã',
      key: 'id',
      tdClass: 'minw-80 sticky-column-left text-primary',
      thClass: 'sticky-column-left',
    },
    {
      label: 'Ảnh',
      key: 'image',
    },
    {
      label: 'Tên',
      key: 'company_name',
      thClass: 'minw-190 text-primary',
      tdClass: 'position-relative',
    },
    {
      label: 'Giá',
      key: 'price',
    },
    {
      label: 'Diện tích',
      key: 'area',
    },
    {
      label: 'Cần chuyển vào',
      key: 'chuyen-vao',
    },
    {
      label: 'Người đăng',
      key: 'nguoi-dang',
    },
    {
      label: 'Ngày đăng',
      key: 'ngay-dang',
    },
  ]

  const routeParams = ref({})
  const totalSuggestedList = ref(0)
  const perPage = ref(10)
  const perPageOptions = [10, 25, 50, 100]
  const currentPage = ref(1)

  const users = computed(() => store.state.app.users)

  const fetchSuggestedList = (id, cb) => fetchSuggestedListCustomer({
    id: id || routeParams.value.id,
    q: http.buildQuery({
      page: currentPage.value - 1,
      perpage: perPage.value,
      ...fitFilter,
    }),
  }, res => {
    suggestedList.value = res.data.result || []
    totalSuggestedList.value = res.data.total || 0
    return cb && cb(res)
  })

  watch(fitFilter, () => {
    fetchSuggestedList()
  })

  watch([currentPage, perPage], () => {
    fetchSuggestedList()
  })

  watch([closeDealExpireYear, closeDealExpireMonth, closeDealSignAtMonth, closeDealSignAtYear], () => {
    const d = new Date(`${closeDealSignAtYear.value || today.getFullYear()}/${closeDealSignAtMonth.value || (today.getMonth() + 1)}`)
    const ey = Number.parseInt(d.getFullYear(), 10) + (Number.parseInt(closeDealExpireYear.value, 10) || 0)
    const em = Number.parseInt(d.getMonth() + 1, 10) + (Number.parseInt(closeDealExpireMonth.value, 10) || 0)
    product.expire_contract_date = new Date(ey, em).toISOString()
  })

  watch(() => closeDealData.value.isNotHaveInfoExpired, value => {
    if (value) {
      closeDealData.value.expireMonth = ''
      closeDealData.value.expireYear = ''
    }
  })

  return {
    product,
    suggestedList,
    productMenuType,
    productMenuTypeVI,
    actions,
    fitFilter,
    tableColumnsSuggestedCustomer,
    closeDealData,
    perPage,
    perPageOptions,
    users,
    routeParams,
    totalSuggestedList,
    currentPage,
    closeDealExpireYear,
    closeDealExpireMonth,
    closeDealSignAtMonth,
    closeDealSignAtYear,
    //
    fetchProduct,
    closeDealProduct,
    openDealProduct,
    refreshProduct,
    fetchSuggestedList,
  }
}
